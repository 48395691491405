<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>财务管理</a-breadcrumb-item>
              <a-breadcrumb-item>账单管理</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div class="Enter-top-right"></div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        style="
          min-width: 1210px;
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['cb.state'].value"
                  v-if="i == '出账状态'"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 未出账 </a-select-option>
                  <a-select-option value="1"> 已出账 </a-select-option>
                </a-select>
                <a-select
                  v-model="params['cb.confirm'].value"
                  v-else-if="i == '结清状态'"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 未结清 </a-select-option>
                  <a-select-option value="1"> 已结清 </a-select-option>
                </a-select>
                <div
                  v-else-if="i == '支付日期'"
                  class="flex-d-row"
                  style="display: inline-block"
                >
                  <a-date-picker
                    placeholder="请输入开始日期"
                    v-model="params['cb.pay_date'].value"
                    style="width: 173.5px"
                  /><span class="a-col-span">-</span>
                  <a-date-picker
                    placeholder="请输入结束日期"
                    v-model="params['cb.pay_date#2'].value"
                    style="width: 173.5px"
                  />
                </div>
                <div
                  v-else-if="i == '账单日期'"
                  class="flex-d-row"
                  style="display: inline-block"
                >
                  <a-date-picker
                    placeholder="请输入开始日期"
                    v-model="params['cb.bill_date'].value"
                    style="width: 173.5px"
                  /><span class="a-col-span">-</span>
                  <a-date-picker
                    v-model="params['cb.bill_date#2'].value"
                    style="width: 173.5px"
                    placeholder="请输入结束日期"
                  />
                </div>
                <div
                  v-else-if="i == '账单金额'"
                  class="flex-d-row"
                  style="display: inline-block"
                >
                  <a-input
                    style="width: 173.5px"
                    v-model="params['cb.total_fee'].value"
                    placeholder="请输入最小金额"
                  /><span class="a-col-span">-</span>
                  <a-input
                    style="width: 173.5px"
                    v-model="params['cb.total_fee#2'].value"
                    placeholder="请输入最大金额"
                  />
                </div>
                <div
                  v-else-if="i == '已支付金额'"
                  class="flex-d-row"
                  style="display: inline-block"
                >
                  <a-input
                    style="width: 173.5px"
                    v-model="params['cb.payed'].value"
                    placeholder="请输入最小金额"
                  /><span class="a-col-span">-</span>
                  <a-input
                    style="width: 173.5px"
                    v-model="params['cb.payed#2'].value"
                    placeholder="请输入最大金额"
                  />
                </div>
                <a-input
                  style="width: 173.5px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  v-else
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        style="background: #fff; padding: 24px; min-width: 1210px; margin: 0"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div>
            <span
              style="position: absolute; top: 22px; left: 76px; color: orange"
              >{{ calltotal }}</span
            >
            <span
              style="position: absolute; top: 22px; left: 198px; color: orange"
              >{{ searchNums.num0 }}</span
            >
            <span
              style="position: absolute; top: 22px; left: 332px; color: orange"
              >{{ searchNums.num1 }}</span
            >
            <span
              style="position: absolute; top: 22px; left: 465px; color: orange"
              >{{ searchNums.num2 }}</span
            >
            <a-tabs :activeKey="keys" @change="callback">
              <a-tab-pane key="-1" tab="所有账单"> </a-tab-pane>
              <a-tab-pane key="1" tab="当月账单"> </a-tab-pane>
              <a-tab-pane key="2" tab="已出未结清"> </a-tab-pane>
              <a-tab-pane key="3" tab="逾期未结清"> </a-tab-pane>
            </a-tabs>
          </div>
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            childrenColumnName="child"
            :columns="columns"
            :data-source="paymentInfolist"
            :rowClassName="rowClassName"
          >
            <span slot="state" slot-scope="text">
              <a-badge
                :status="text | statusTypeFilter"
                :text="text | statusFilter"
              />
            </span>
            <span slot="confirm" slot-scope="text">
              <a-badge
                :status="text | confirmTypeFilter"
                :text="text | confirmFilter"
              />
            </span>
            <span slot="overd" slot-scope="text, record">
              <a-badge
                :status="record | rentStatusTypeoverdue"
                :text="record | rentStatusoverdue"
              />
            </span>
            <template slot="action" slot-scope="text, record">
              <a
                v-show="
                  record.state == 1 && record.confirm == 0 && record == null
                "
                @click="onbackbill(record)"
                >撤销出账</a
              >
              <a v-show="record.state == 0" @click="onEdit(record)">调整账单</a
              >&nbsp;&nbsp;
              <a v-show="record.state == 0" @click="onSure(record)">确认账单</a>
              <a v-show="record.state == 1" @click="onSure(record)">账单明细</a>
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="1"
            :total="total"
            @change="onpagesize"
          />
          <a-modal
            :title="title"
            :visible="modelvisible"
            width="1100px"
            @cancel="onmodelClose"
          >
            <a-descriptions v-show="modelvisible" :column="5" title="">
              <a-descriptions-item label="客户">
                {{ list.clientName }}
              </a-descriptions-item>
              <a-descriptions-item label="账单日期">
                {{ list.billDate }}
              </a-descriptions-item>
              <a-descriptions-item label="支付日期">
                {{ list.payDate }}
              </a-descriptions-item>
              <a-descriptions-item label="金额">
                {{ list.totalFee }}
              </a-descriptions-item>
              <a-descriptions-item label="已支付">
                {{ list.payed }}
              </a-descriptions-item>
            </a-descriptions>
            <a-table
              rowKey="id"
              childrenColumnName="child"
              :columns="modelcolumns"
              :data-source="data"
              bordered
            >
              <span slot="startDate" slot-scope="text, record"
                >{{ record.startDate }}-{{ record.endDate }}
              </span>
              <template
                v-for="col in ['name', 'startDate', 'totalFee']"
                :slot="col"
                slot-scope="text, record, index"
              >
                <div :key="col">
                  <a-range-picker
                    v-if="
                      col == 'startDate' &&
                      numb == 1 &&
                      index + 1 == data.length
                    "
                    :disabled="!record.editable"
                    v-model="dayDate"
                  />
                  <span v-else-if="col == 'startDate'"
                    >{{ record.startDate }}~{{ record.endDate }}</span
                  >
                  <a-input
                    v-else-if="record.editable && col == 'name'"
                    style="margin: -5px 0"
                    :value="text"
                    placeholder="请输入费用名称"
                    @change="
                      e => handleChange(e.target.value, record.key, col, index)
                    "
                  />
                  <a-input
                    v-else-if="record.editable && col == 'totalFee'"
                    style="margin: -5px 0"
                    :value="text"
                    placeholder="请输入金额"
                    @change="
                      e => handleChange(e.target.value, record.key, col, index)
                    "
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template slot="operation" slot-scope="text, record, index">
                <div class="editable-row-operations">
                  <span v-if="record.editable">
                    <a @click="() => save(record.key, index)">保存</a>&nbsp;
                    <a-popconfirm
                      title="Sure to cancel?"
                      @confirm="() => cancel(record.key, index)"
                    >
                      <a>取消</a>
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a v-if="numb != 1" :disabled="numb != 1">修改</a>
                    <a
                      v-else
                      :disabled="index + 1 !== data.length"
                      @click="() => edit(record.key, index)"
                      >修改</a
                    >
                    <a-popconfirm
                      v-show="numb == 1 && index + 1 == data.length"
                      style="margin-left: 5px"
                      title="你确认要删除该账单么?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="ondelete(index)"
                      @cancel="cancel"
                    >
                      <a>删除</a>
                    </a-popconfirm>
                  </span>
                </div>
              </template>
            </a-table>
            <template slot="footer">
              <a-button
                @click="onAddlist"
                :disabled="numb == 0 ? false : 'disabled'"
                type="primary"
                >添加</a-button
              >
              <a-button @click="onsubmit" type="primary"> 提交 </a-button>
              <a-button @click="onmodelClose">取消</a-button>
            </template>
          </a-modal>
          <a-modal
            :title="title"
            :visible="modelvisibles"
            width="1100px"
            @ok="onSuresub"
            @cancel="onmodelClose"
          >
            <a-descriptions v-show="modelvisibles" :column="5" title="">
              <a-descriptions-item label="客户">
                {{ list.clientName }}
              </a-descriptions-item>
              <a-descriptions-item label="账单日期">
                {{ list.billDate }}
              </a-descriptions-item>
              <a-descriptions-item label="支付日期">
                {{ list.payDate }}
              </a-descriptions-item>
              <a-descriptions-item label="金额">
                {{ list.totalFee }}
              </a-descriptions-item>
              <a-descriptions-item label="已支付">
                {{ list.payed }}
              </a-descriptions-item>
            </a-descriptions>
            <a-table
              rowKey="id"
              childrenColumnName="child"
              :columns="modelcolumn"
              :data-source="data"
              class="components-table-demo-nested"
            >
              <span slot="startDate" slot-scope="text, record"
                >{{ record.startDate }}-{{ record.endDate }}
              </span>
            </a-table>
          </a-modal>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '客户',
    dataIndex: 'clientName',
  },
  {
    title: '账单日期',
    dataIndex: 'billDate',
  },
  {
    title: '支付日期',
    dataIndex: 'payDate',
  },
  {
    title: '金额',
    dataIndex: 'totalFee',
  },
  {
    title: '已支付',
    dataIndex: 'payed',
  },
  {
    title: '出账状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '结清状态',
    dataIndex: 'confirm',
    scopedSlots: { customRender: 'confirm' },
  },
  {
    title: '逾期状态',
    dataIndex: 'payDate',
    scopedSlots: { customRender: 'overd' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const modelcolumn = [
  {
    title: '费用名称',
    dataIndex: 'name',
  },
  {
    title: '起止时间',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' },
  },
  {
    title: '金额',
    dataIndex: 'totalFee',
    scopedSlots: { customRender: 'totalFee' },
  },
]
const modelcolumns = [
  {
    title: '费用名称',
    dataIndex: 'name',
    width: '20%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '起止时间',
    dataIndex: 'startDate',
    width: '40%',
    scopedSlots: { customRender: 'startDate' },
  },
  {
    title: '金额',
    dataIndex: 'totalFee',
    width: '20%',
    scopedSlots: { customRender: 'totalFee' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]
const statusMap = {
  0: {
    status: 'warning',
    text: '草稿',
  },
  1: {
    status: 'processing',
    text: '提交审核',
  },
  2: {
    status: 'success',
    text: '审核通过',
  },
  3: {
    status: 'error',
    text: '审核拒绝',
  },
}
const stateMap = {
  0: {
    status: 'warning',
    text: '未出账',
  },
  1: {
    status: 'processing',
    text: '已出账',
  },
}
const confirmMap = {
  0: {
    status: 'warning',
    text: '未结清',
  },
  1: {
    status: 'processing',
    text: '已结清',
  },
}
const overdue = {
  0: {
    status: 'success',
    text: '未逾期',
  },
  1: {
    status: 'processing',
    text: '逾期已结清',
  },
  2: {
    status: 'error',
    text: '逾期未结清',
  },
}
import moment from 'moment'
import http from '../../../http'
export default {
  data() {
    return {
      numb: '0',
      columns,
      editingKey: '',
      moment,
      previewVisible: false,
      previewV: false,
      previewImage: '',
      visible: false,
      aaa: false,
      dayDate: [],
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      data: [],
      cacheData: null,
      title: '',
      modelcolumn,
      textlist: [
        '账单日期',
        '支付日期',
        '已支付金额',
        '账单金额',
        '客户名称',
        '结清状态',
        '出账状态',
      ],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      modelcolumns,
      modelvisible: false,
      modelvisibles: false,
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      searchNums: {},
      calltotal: '',
      keys: '',
      params: {
        current: 1,
        pageSize: 10,
        'cc.name': {
          value: '',
          op: '%like%',
        },
        'cb.bill_date': {
          value: undefined,
          op: '>=',
        },
        'cb.bill_date#2': {
          value: undefined,
          op: '<=',
        },
        'cb.pay_date': {
          value: undefined,
          op: '>=',
        },
        'cb.pay_date#2': {
          value: undefined,
          op: '<=',
        },
        'cb.total_fee': {
          value: undefined,
          op: '>=',
        },
        'cb.total_fee#2': {
          value: undefined,
          op: '<=',
        },
        'cb.payed': {
          value: undefined,
          op: '>=',
        },
        'cb.payed#2': {
          value: undefined,
          op: '<=',
        },
        'cb.state': {
          value: '',
          op: '=',
        },
        'cb.confirm': {
          value: '',
          op: '=',
        },
      },
      total: 1,
      paymentInfolist: [],
      selectedRowKey: [],
      Id: '',
      list: [],
      proof: [],
      rimages: '',
      form: {
        id: '',
        name: '',
        startDate: '',
        endDate: '',
        fee: '',
      },
      e: '',
    }
  },
  filters: {
    rentStatusoverdue(type) {
      if (type == null) {
        return ' '
      } else {
        if (moment(moment().format('YYYY-MM-DD')).isBefore(type.payDate)) {
          return overdue[0].text
        } else {
          if (type.confirm == '0') {
            return overdue[2].text
          } else {
            return overdue[1].text
          }
        }
      }
    },
    rentStatusTypeoverdue(type) {
      if (type == null) {
        return ' '
      } else {
        if (moment(moment().format('YYYY-MM-DD')).isBefore(type.payDate)) {
          return overdue[0].status
        } else {
          if (type.confirm == '0') {
            return overdue[2].status
          } else {
            return overdue[1].status
          }
        }
      }
    },
    stateFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return statusMap[type].text
      }
    },
    stateTypeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return statusMap[type].status || ''
      }
    },
    statusFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return stateMap[type].text
      }
    },
    statusTypeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return stateMap[type].status || ''
      }
    },
    confirmFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return confirmMap[type].text
      }
    },
    confirmTypeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return confirmMap[type].status || ''
      }
    },
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    handleSearch(e) {
      this.keys = '-1'
      e.preventDefault()
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['cc.name'].value = values.客户名称
      })
      this.getoperatebill()
    },
    handleReset() {
      this.fom.resetFields()
    },

    toggle() {
      this.expand = !this.expand
    },
    handleChange(value, key, column, index) {
      const newData = [...this.data]
      const target = newData.filter(item => key === item.key)[index]
      if (target) {
        target[column] = value
        this.data = newData
      }
    },
    edit(key, index) {
      const newData = [...this.data]
      const target = newData.filter(item => key === item.key)[index]
      this.editingKey = key
      if (target) {
        target.editable = true
        this.data = newData
      }
    },
    save(key, index) {
      this.cacheData = this.data.map(item => ({ ...item }))
      const newData = [...this.data]
      const newCacheData = [...this.cacheData]
      const target = newData.filter(item => key === item.key)[index]
      const targetCache = newCacheData.filter(item => key === item.key)[index]
      if (target && targetCache) {
        delete target.editable
        this.data = newData
        Object.assign(targetCache, target)
        this.cacheData = newCacheData
      }
      this.editingKey = ''
    },
    cancel(key, index) {
      this.cacheData = this.data.map(item => ({ ...item }))
      const newData = [...this.data]
      const target = newData.filter(item => key === item.key)[index]
      this.editingKey = ''
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter(item => key === item.key)[index]
        )
        delete target.editable
        this.data = newData
      }
    },
    handleCancel() {
      this.previewVisible = false
    },
    onmodelClose() {
      this.modelvisible = false
      this.modelvisibles = false
      this.numb = 0
      this.dayDate = []
    },
    // 删除
    onDetele() {
      this.deleteModule()
    },
    async deleteModule() {
      try {
        const res = await http.deleteModule(this.selectedRowKey)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    callback(e) {
      if (e == undefined) {
        this.keys = '-1'
      } else {
        this.keys = e
      }
      this.params.current = 1
      this.params['cb.bill_date'].value = ''
      this.params['cb.bill_date#2'].value = ''
      this.params['cb.pay_date'].value = ''
      this.params['cb.pay_date#2'].value = ''
      this.params['cb.state'].value = ''
      this.params['cb.confirm'].value = ''
      if (e == 1) {
        const startDate = moment()
          .month(moment().month())
          .startOf('month')
          .format('YYYY-MM-DD')
        const endDate = moment()
          .month(moment().month())
          .endOf('month')
          .format('YYYY-MM-DD')
        this.params['cb.bill_date'].value = startDate
        this.params['cb.bill_date#2'].value = endDate
      } else if (e == 2) {
        this.params['cb.state'].value = '1'
        this.params['cb.confirm'].value = '0'
        this.params['cb.pay_date'].value = moment().format('YYYY-MM-DD')
      } else if (e == 3) {
        this.params['cb.state'].value = '1'
        this.params['cb.confirm'].value = '0'
        this.params['cb.pay_date#2'].value = moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      }
      this.getoperatebill()
    },
    //标签个数
    async getbillsearchNum() {
      try {
        const res = await http.getbillsearchNum(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.searchNums = data
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    // 编辑
    onEdit(item) {
      this.list = item
      this.form.id = item.id
      this.title = '调整账单'
      this.modelvisible = true
      this.getPaymentInfoId(item.id)
    },
    onsubmit() {
      if (this.numb == 1) {
        if (
          this.dayDate == '' ||
          this.data[this.data.length - 1].totalFee == ''
        ) {
          this.$message.error('请前往修改')
          return
        }
        this.form.name = this.data[this.data.length - 1].name
        this.form.fee = this.data[this.data.length - 1].totalFee
        this.form.startDate = moment(this.dayDate[0]).format('YYYY-MM-DD')
        this.form.endDate = moment(this.dayDate[1]).format('YYYY-MM-DD')
        this.postoperateokId(this.form.id, this.form)
      }
      //   console.log("调整提交按钮");
    },
    onAddlist() {
      this.data.push({
        name: '',
        startDate: '',
        totalFee: '',
      })
      let da = []
      da.push(this.data[0].startDate)
      da.push(this.data[0].endDate)
      this.dayDate = da
      this.numb = '1'
      //   console.log(this.data);
    },
    ondelete(e) {
      this.data.splice(e, 1)
      this.numb = 0
      //   console.log(this.data);
    },
    //确认
    onlook(item) {
      this.form.id = item.id
      this.list = item

      this.modelvisibles = true
      this.getPaymentInfoId(item.id)
    },
    //确认
    onSure(item) {
      this.list = item
      if (item.state == 0) {
        this.form.id = item.id
        this.title = '确认账单'
      } else {
        this.form.id = ''
        this.title = '账单明细'
      }
      this.modelvisibles = true
      this.getPaymentInfoId(item.id)
    },
    onSuresub() {
      if (this.form.id == '') {
        this.modelvisibles = false
        return
      }
      this.getoperateokId(this.form.id)
    },
    async postoperateokId(id, form) {
      try {
        const res = await http.postoperateokId(id, form)
        const { success, msg } = res.data
        if (success) {
          this.numb = 0
          this.$message.success('提交成功')
          this.onmodelClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onbackbill(e) {
      this.getoperateokback(e.id)
    },
    async getoperateokback(id) {
      try {
        const res = await http.getoperateokback(id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
          this.onmodelClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async getoperateokId(id) {
      try {
        const res = await http.getoperateokId(id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onmodelClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onicon() {
      window.open('http://www.fontawesome.com.cn/faicons/')
    },
    onpagesize(e) {
      this.params.current = e
      this.getoperatebill()
    },
    async getoperatebill() {
      try {
        const res = await http.getoperatebill(this.params)
        const { success, data } = res.data
        if (success) {
          this.paymentInfolist = data.bills.records
          this.total = data.bills.total
          if (this.keys == '-1') {
            this.calltotal = data.bills.total
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getPaymentInfoId(id) {
      try {
        const res = await http.getPaymentInfoId(id)
        const { success, data } = res.data
        if (success) {
          this.data = data.bills
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getoperatebill()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getbillsearchNum()
    this.callback()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  border-radius: 3px;
  position: relative;
  min-height: 630px;
  //   height: 100%;
  padding: 10px 0 6px;
}
.ant-upload-text {
  font-size: 13px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 123px;
}
.a-col-span {
  display: inline-block;
  width: 24px;
  text-align: center;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  height: 80px;
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-upload.ant-upload-select-picture-card,
::v-deep .ant-upload-list-picture-card .ant-upload-list-item,
::v-deep .ant-upload-list-picture-card-container {
  width: 70px;
  height: 70px;
}
</style>
